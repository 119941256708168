import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { FSOverlay } from '~/components/common';
import { Layout } from '~/components/common/layout/Layout';
import { Frontmatter } from '~/config';

// don't add export - uses local gatsby plugin
// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: '404 Not Found',
  screenShoot: true,
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout>
      <FSOverlay center>Page not found.</FSOverlay>
    </Layout>
  );
};

export default Page;
